<template>
  <client-only>
    <!--  TODO - REAL ID -->
    <div class="yotpo bottomLine" :data-product-id="productId"></div>
  </client-only>
</template>

<script setup lang="ts">
useYotpoRefresh();

defineProps({
  productId: {
    type: Number,
    required: true,
  },
});
</script>
